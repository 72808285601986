import React from "react" 
import styled from "styled-components";

// Section Component Imports
import Navigation from "../components/navigation/Navigation";
import Footer from "../components/Footer";

// Components
import {HeadingOne, HeadingTwo} from "../components/Headings";
import Skepta from "../components/wrappers/Skepta";
import {ImageFeatureBox} from "./../components/FeatureBoxes";
import {ExternalLinkButton} from "./../components/Buttons";
import PaddedImage from "./../components/PaddedImage";

import SEO from "../components/seo"

import {devices} from "./../components/devices";

// Image Imports
import gniLogo from "./../images/gni-logo.png";
import cozadLogo from "./../images/cozad-logo.png";

import gulluImage from "./../images/culture/4_gullu.jpg";
import firstPitchImage from "./../images/culture/1_firstPitch.jpg";
import nathuCoffeeImage from "./../images/culture/2_nathuCoffee.png";
import sidhantUXImage from "./../images/culture/6_sidhantUX.png";
import workSpilloverImage from "./../images/culture/3_workSpillover.jpg";
import trainHackathonImage from "./../images/culture/5_trainHackathon.jpg";

const IndexPage = () => (
  <>
    <SEO title="About "/>
    <Navigation/>
    <SectionContainer>
        <Skepta>
            <HeadingOne>Mission</HeadingOne>
            <HeadingTwo>We want to enable news to remain free, and publications to remain independent.</HeadingTwo>
            <p>Staying informed is an integral part of human nature - and now, more than ever, it is an essential requirement. As the digital age has progressed, sources of news have multiplied, attention spans have decreased and channels of revenue have increasingly come under control of providers of distribution, rather than generators of content. Creators of this content play a critical role in our society in terms of shaping opinions and disbursing facts - and Turkbox aims to provide an alternate source of revenue to these crucial actors: embeddable web widgets that grant access to articles in exchange for the completion of simple data classification tasks.</p>
            <p>Digital journalism dominated by paywalls tides against the inclusivity that is a founding principle of the internet. The ability to know about the world must not remain confined to those within a walled garden: Turkbox wants to leverage the need for classified data to help support independent organisations trying to keep news free, and thus, knowledge open.</p>
            <HeadingOne margin={`25px 0 15px 0`}>Support & Funding</HeadingOne>
            <FeatureBoxFlex>
                <ImageFeatureBox imageUrl={gniLogo} imageAlt={`Logo for the Google News Initiative`} title={`Google News Initiative`} backgroundColor={`#fff`} height={`auto`}>
                    Turkbox was a recipient of the Google News Initiative's Asia-Pacific Innovation Challenge in 2019, specifically for supporting our pilot project.
                    <ButtonSeparatorPara><ExternalLinkButton target="_blank" rel="noopener noreferrer" href={"https://www.blog.google/around-the-globe/google-asia/here-are-winners-gni-innovation-challenge-asia-pacific/"}>Read More</ExternalLinkButton></ButtonSeparatorPara>
                    </ImageFeatureBox>
                <ImageFeatureBox imageUrl={cozadLogo} imageAlt={`Logo for the Cozad New Venture Competition`} icon={`DataProvider`} title={`Cozad NVC`} backgroundColor={`#fff`} height={`auto`}>
                    Turkbox placed 4th at the Cozad New Venture Challenge, and picked up the Huawei Best Software solution and Meyer Capel Prize 
                    <ButtonSeparatorPara><ExternalLinkButton target="_blank" rel="noopener noreferrer" href={`https://tec.illinois.edu/news/articles-from-tec/30694`}>Read More</ExternalLinkButton></ButtonSeparatorPara>
                </ImageFeatureBox>
            </FeatureBoxFlex>
            <HeadingOne margin={`25px 0 15px 0`}>Culture</HeadingOne>
            <p>We're a small team of engineers, designers and product managers, mostly remote and with varying levels of involvement with the project. We sometimes operate out of our office in Sarai Jullena, New Delhi.</p>
            <p>If you're interested in what we're building, and have something to contribute, send us your résumé at <a href="mailto: team@turkbox.co">team@turkbox.co</a>, or check out our openings on <a href="https://angel.co/company/turkbox/jobs">AngelList</a>.</p>
            <FeatureBoxFlex>
                <GalleryImage className="highlight" captionWidth={`95%`} src={firstPitchImage} width={`30%`}>The first time Tanay pitched Turkbox at SocialFuse in November 2018</GalleryImage>
                <GalleryImage className="highlight" captionWidth={`95%`} src={nathuCoffeeImage} width={`30%`}>Prannay leading a meeting on platform architecture</GalleryImage>
                <GalleryImage className="highlight" captionWidth={`95%`} src={workSpilloverImage} width={`30%`}>Our meetings often spill over to nearby coffee shops</GalleryImage> 
                <GalleryImage className="highlight" captionWidth={`95%`} src={gulluImage} width={`30%`}>Gullu can be found at the bottom of the staircase leading up to our office</GalleryImage>
                <GalleryImage className="highlight" captionWidth={`95%`} src={trainHackathonImage} width={`30%`}>Trying to ship products on time sometimes leads to impromptu train hackathons</GalleryImage>
                <GalleryImage className="highlight" captionWidth={`95%`} src={sidhantUXImage} width={`30%`}>Sidhant leading a user research session in Banda, Uttar Pradesh</GalleryImage>               
            </FeatureBoxFlex>
        </Skepta>
    </SectionContainer>
    <Footer/>
  </>
)

const SectionContainer = styled.section`
    background-color: #F4F4F0;
    min-height: calc(100%);
    width: 100%;
    padding: 90px 0 5% 0;

    @media ${devices.tablet} {
        padding: 10% 0 5% 0;
    }

    a {
        color: #4a4a4a;

        &:hover {
            color: #000;
            
        }
    }
    
`;

const ButtonSeparatorPara = styled.p`
    margin: 15px 0;
    text-align: center;
`;

const FeatureBoxFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    text-align: center;

    div.highlight {
        margin-bottom: 40px;
    }
`;

const GalleryImage = styled(PaddedImage)`
    @media ${devices.tablet} {
        width: 30%;
    }

    width: 48%;
`

export default IndexPage
